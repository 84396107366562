<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >员工名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.userName"
                :fetch-suggestions="userNameQuerySearch"
                placeholder="请输入或选择员工名称"
                @select="onProkectUserNameSelect"
                :popper-append-to-body="false"
                clearable
              >
              </el-autocomplete
            ></span>
            <span>
              申请编号：
              <el-input
                v-model="parameter.applicantName"
                class="ipt_width expense__width"
                placeholder="请输入申请编号"
                clearable
              >
              </el-input>
            </span>
            <span
              >项目名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.projectName"
                :fetch-suggestions="projectQuerySearch"
                placeholder="请输入或选择项目名"
                @select="onProkectNameSelect"
              >
              </el-autocomplete>
            </span>
            <span
              >申请时间：
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker
            ></span>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">审核流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType">
            <el-tab-pane name="APPROVAL" label="待审核"></el-tab-pane>
            <el-tab-pane name="FLOW_END" label="已审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT" label="不通过"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            row-key="id"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              width="70"
              label="序号"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="approvalNumber"
              label="申请编号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="applicantName"
              sortable
              width="100"
              label="申请人"
            >
            </el-table-column>
            <el-table-column align="center" prop="deptName" sortable min-width="120" label="部门">
            </el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              label="项目名称"
              sortable
              :show-overflow-tooltip="false"
              min-width="320"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="taskDate"
              min-width="130"
              sortable
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" prop="statusName" min-width="120">
            </el-table-column>

            <el-table-column label="操作" align="center" width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="parameter.approvalType == 'APPROVAL'"
                  size="small"
                  class="text_Examine_Bgc"
                  @click.stop="onDetaiFn(scope.row)"
                  >审核</el-button
                >
                <el-button
                  type="text"
                  v-if="parameter.approvalType != 'APPROVAL'"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="onDetaiFn(scope.row, true)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 审核 -->
    <EditDialog :isShow.sync="auditDailog" :width="'80%'">
      <template v-slot:title>
        <span style="color: #409eff">核算审核</span>
      </template>
      <template v-slot:content>
        <div class="projectProgress">
          内部生产成本核算类型：{{
            auditForm.isTaskProject == 1
              ? '基于项目组成员审核工时核算法'
              : '基于具体任务(或单价)核算法'
          }}
        </div>
        <el-table :data="auditTableData" border height="300">
          <template v-if="auditForm.isTaskProject == 2">
            <el-table-column
              label="任务名称"
              fixed="left"
              align="center"
              prop="taskName"
              min-width="200"
            >
            </el-table-column>
            <el-table-column label="任务类型" align="center" prop="isUnitPriceTask" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.isUnitPriceTask == 0 ? '基于具体任务' : '基于具体单价' }}
              </template>
            </el-table-column>
            <el-table-column label="任务价格" align="center" prop="taskPrice" min-width="90">
              <template slot-scope="scope">
                {{ scope.row.taskPrice | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column label="任务进度" prop="taskProgress" min-width="80" align="center">
              <template slot-scope="scope">
                {{ (scope.row.taskProgress * 100).toFixed(1) }}%
              </template>
            </el-table-column>
            <el-table-column label="任务单价" align="center" prop="taskUnitPrice" min-width="90">
              <template slot-scope="scope">
                {{ scope.row.taskUnitPrice | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              label="计件单元"
              align="center"
              prop="quantityCompleted"
              min-width="90"
            >
              <template slot-scope="scope">
                {{ scope.row.quantityCompleted | applyAmount }}
              </template>
            </el-table-column>

            <el-table-column label="执行人" align="center" prop="executor" min-width="90">
            </el-table-column>
            <el-table-column label="创建人" align="center" prop="assigneeName" min-width="90">
            </el-table-column>
            <el-table-column label="任务所属部门" align="center" prop="department" min-width="110">
            </el-table-column>

            <el-table-column label="创建任务时间" prop="createdDate" min-width="105" align="center">
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              label="预计完成时间"
              prop="completeDate"
              min-width="105"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.completeDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              label="最新修改时间"
              prop="lastModifyDate"
              min-width="105"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.lastModifyDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="任务内容" align="center" prop="taskContent" min-width="200">
            </el-table-column>
            <el-table-column
              label="操作"
              v-if="!auditForm.isShow"
              fixed="right"
              align="center"
              width="70"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="onAddTask(scope.row)"
                  class="text_Edit_Bgc"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column label="记录时间" align="center" prop="monthly" min-width="110">
              <template slot-scope="scope">
                {{ scope.row.monthly | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="总进度" prop="progress" min-width="100" align="center">
              <template slot-scope="scope"> {{ scope.row.progress * 100 }}% </template>
            </el-table-column>

            <el-table-column
              label="本月完成工作内容"
              align="center"
              prop="completedWork"
              min-width="200"
            >
            </el-table-column>
            <el-table-column
              label="剩余工作内容"
              align="center"
              prop="remainingWork"
              min-width="200"
            >
            </el-table-column>
            <el-table-column label="创建人" align="center" prop="createName" min-width="90">
            </el-table-column>
          </template>
        </el-table>
        <OpinionArea ref="opinionArea" :procInstId="processInstId" :title="'意见区'"></OpinionArea>
      </template>
      <template v-slot:footer>
        <template v-if="!auditForm.isShow">
          <el-button @click="onAuditOpinionFn(1)" type="success">通过</el-button>
          <el-button @click="onAuditOpinionFn(2)" type="danger">不通过</el-button>
        </template>
        <el-button type="info" plain @click="auditDailog = false">取消</el-button>
      </template>
    </EditDialog>
    <EditDialog :isShow.sync="audit0pinionDailog">
      <template v-slot:title>
        <span style="color: #409eff">核算审核</span>
      </template>
      <template v-slot:content>
        <div>审批意见</div>
        <el-input v-model="opinion" type="textarea"></el-input>
        <div v-show="auditIsShow && !opinion" style="color: red">请输入审批不通过意见</div>
      </template>
      <template v-slot:footer>
        <el-button
          v-if="auditForm.index == 1"
          @click="onAuditSubmit(1)"
          :loading="btnLoading"
          type="success"
          >通过</el-button
        >
        <el-button
          v-if="auditForm.index == 2"
          @click="onAuditSubmit(2)"
          :loading="btnLoading"
          type="danger"
          >不通过</el-button
        >
        <el-button type="info" plain :loading="btnLoading" @click="audit0pinionDailog = false"
          >取消</el-button
        >
      </template>
    </EditDialog>

    <!-- 单价 -->
    <AssigningTask
      v-if="assigningDailog"
      :isShow.sync="assigningDailog"
      :options="assigningInfo"
      :isAuditShow="true"
      @updateAudit="updateAudit"
    />
    <el-dialog title="审核流程图" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/任务审核流程图.png" alt="任务审核流程图" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Edit: () => import('../Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    AssigningTask: () => import('@/components/project/assigningTask.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'APPROVAL',
        applicantName: '',
        projectName: '',
        userName: '',
        beginDate: '',
        endDate: '',
      },
      pageSize: 0,
      tableData: [],
      loading: false,
      auditDailog: false,
      projectList: [],
      auditTableData: [],
      auditForm: {},
      auditIsShow: false,
      btnLoading: false,
      createdDate: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      assigningInfo: {},
      assigningDailog: false,
      audit0pinionDailog: false,
      opinion: '',
      processInstId: '',
      dialogVisible: false,
    }
  },
  watch: {
    'parameter.approvalType': {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
          this.parameter.beginDate = ''
          this.parameter.endDate = ''
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
    this.getUserNameList()
  },
  filters: {
    applyAmount(n) {
      if (!n) {
        return n
      } else {
        return Number(n).toLocaleString()
      }
    },
  },
  methods: {
    updateAudit(val) {
      val.taskProgress = val.taskProgress / 100
      const info = this.auditTableData.find(item => item.id === val.id)
      if (info) {
        for (const key in info) {
          if (Object.hasOwnProperty.call(info, key)) {
            if (key != 'id') {
              info[key] = val[key]
            }
          }
        }
      }
    },
    // 单价任务编辑
    async onAddTask(row = {}) {
      const res = await this.$api.project.selectDetailById(row.projectId)
      if (res.data) {
        this.$store.commit({
          type: 'project/SET_PROJECTINFORMATION',
          projectInformation: res.data.projectInformation,
        })
        this.$store.commit({
          type: 'project/SET_PROJECTDEPTMEMBERANDMANGAHERLIST',
          projectDeptMemberAndManagerList: res.data.projectDeptMemberAndManagerList,
        })
      }
      this.assigningInfo = row.deepClone()
      this.assigningInfo.taskProgress = this.assigningInfo.taskProgress * 100
      this.assigningInfo.isPass = 0
      this.assigningInfo.type = true
      this.assigningDailog = true
    },
    onAuditOpinionFn(index) {
      this.auditForm.index = index
      this.auditIsShow = false
      this.opinion = ''
      this.audit0pinionDailog = true
    },
    // 任务审核
    async onAuditSubmit(index) {
      if (index == 2 && !this.opinion) {
        this.auditIsShow = true
        return
      }
      this.btnLoading = true
      const obj = {
        id: this.auditForm.id,
        opinion: this.opinion,
        projectId: this.auditForm.projectId,
      }
      if (this.auditForm.isTaskProject == 2) {
        obj.xmTaskAssignResponseList = this.auditTableData
      }
      const key = index == 1 ? 'disposeTask' : 'approvalReject'
      try {
        const res = await this.$api.project[key](obj)
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.auditDailog = false
          this.audit0pinionDailog = false
          this.getData()
          this.btnLoading = false
        }
      } catch (error) {
        this.btnLoading = false
      }
    },
    async onDetaiFn(row, show = false) {
      this.processInstId = row.processInstId
      this.auditTableData = []
      this.auditForm.projectId = row.projectId
      this.auditForm.id = row.id
      this.auditForm.isShow = show
      const res = await this.$api.project.taskAssignSelectDetailById({
        projectId: row.projectId,
        approvalType: this.parameter.approvalType,
      })
      if (res.code == 200) {
        const { response = {} } = res.data
        if (response.isTaskProject == 1) {
          this.auditTableData = response.xmScheduleResponseList || []
        } else if (response.isTaskProject == 2) {
          this.auditTableData = response.xmTaskAssignResponseList || []
        }
        this.auditForm.isTaskProject = response.isTaskProject
        this.auditDailog = true
      } else {
        this.$message.error('请求失败')
      }
    },
    getUserNameList(deptId = '') {
      this.$api.user
        .listStaff({ deptId, pageSize: 9999, pageNow: 1 })
        .then(res => {
          this.userNameList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    userNameQuerySearch(queryString, cb) {
      let userNameList = this.userNameList.map(item => {
        return { value: item.userName }
      })
      let results = queryString ? this.userNameCreateFilter(queryString) : userNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    userNameCreateFilter(queryString) {
      let results = this.userNameList.map(item => {
        return { value: item.userName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectUserNameSelect(e) {
      if (e) {
        this.parameter.userName = e.value
      }
      this.$forceUpdate()
    },
    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.projectName = e.value
      }
      this.$forceUpdate()
    },
    handleReset() {
      this.parameter.projectName = ''
      this.parameter.userName = ''
      this.parameter.applicantName = ''
      this.createdDate = []
      this.parameter.pageNow = 1
      this.parameter.beginDate = ''
      this.parameter.endDate = ''
      this.getData()
    },
    getProject() {
      this.$api.project
        .listProject({ pageSize: 10000, pageNow: 1 })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate.length !== 0) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }
      this.$api.project
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records || []
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.projectProgress {
  color: #000;
  font-size: 18px;
}
</style>
